<template>
    <div id="cont_Inicio" class="">
        <div class="main-content 
                    flex-1 
                    bg-gray-100 
                    mt-24 lg:mt-12">
            <div class="bg-gray-800 pt-3">
                <div
                    class="
                    rounded-tl-3xl
                    bg-gradient-to-r
                    from-blue-900
                    to-gray-800
                    p-4
                    shadow
                    text-2xl text-white
                    "
                >
                    <div v-if=" infoAgentesWolkvoxIdCampActiva != undefined">
                        <div v-if="infoAgentesWolkvoxIdCampActiva == 0" class="absolute block transform-gpu transition ease-in-out hover:scale-105 duration-300" @click="crearCampWolkvox()">
                            <ul class="list-reset">
                                <li class="btn-Wolkvox">
                                    <img src="/img/Wolkvox_Agent_Icon.png" alt="">
                                </li>
                            </ul>      
                        </div> 
                        <div v-else class="absolute block transform-gpu transition ease-in-out hover:scale-105 duration-300" @click="cerrarCampWolkvox()">
                            <ul class="list-reset cont_Camp-Wolkvox">
                                <li class="Camp-Wolkvox font-sans">
                                    <img src="/img/Wolkvox_Agent_Icon.png" alt="">
                                    <span># {{ infoAgentesWolkvoxIdCampActiva }}</span>
                                    <i class="fas fa-times-circle"></i>
                                </li>
                            </ul>      
                        </div> 
                    </div>
                    <h3 class=" relative inline-block font-bold pl-2 font-sans">Cobranzas Pendientes</h3>
                    <div class=" btn_refrescar btn_pag absolute cursor-pointer rounded-full h-9 w-9 ml-3 " @click="getFacturasClientes()">
                        <i class=" relative text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                width="30" height="30"
                                viewBox="0 0 30 30"
                                style=" fill:#FFF;">
                                <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
            <div id="cont_ppal" class="w-full md:w-10/12 mx-auto p-6 bg-white shadow-xl rounded-b-md z-10">
                <div id="cont_opciones" class=" grid grid-cols-4 md:grid-cols-7 mb-6 gap-6">
                    <div class="search-box relative inline-block col-span-2 grid grid-cols-10 ">
                        <span class=" absolute label-filtro">Buscar Cédula:</span> 
                        <div class="col-span-8">
                            <input
                                v-model="buscarCedula"
                                @keyup="findtext()"
                                type="number"
                                class="search-input left-0 top-0 h-9 bg-white"
                                placeholder="Cédula..."
                            />
                        </div>
                        <div class="col-span-2">
                            <button @click="findtext" class="search-button right-0 top-0 h-9 bg-white col-span-1">
                                <i class="fas fa-search w-full"></i>
                            </button>
                        </div>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Campañas:</span> 
                        <select
                        id="buscarzona"
                        v-model="buscarCampania"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        @change="findCampania"
                        >
                            <option disabled selected>Campañas</option>
                            <option v-for="(campania, index) in campanias" :value="campania" :key="index">
                                {{ campania }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box relative inline-block mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Cortes:</span> 
                        <select
                        id="buscarzona"
                        v-model="buscarCorte"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        @change="findCorte"
                        >
                            <option disabled selected>Cortes</option>
                            <option v-for="(corte, index) in cortes" :value="corte" :key="index">
                                {{ corte }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Regiones:</span> 
                        <select
                            id="buscarzona"
                            v-model="buscarRegion"
                            class="border rounded-xl shadow h-9 bg-white w-full"
                            @change="findRegiones"
                        >
                            <option disabled selected>Regiones</option>
                            <option v-for="(region, index) in regiones" :value="region" :key="index">
                                {{ region }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Zonas:</span> 
                        <select
                        id="buscarzona"
                        v-model="buscarZona"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        @change="findZona"
                        >
                            <option disabled selected>Zonas</option>
                            <option v-for="(zona, index) in zonas" :value="zona" :key="index">
                                {{ zona }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Dias Venc.:</span> 
                        <select
                        id="buscarzona"
                        v-model="buscarDVenc"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        @change="findDVenc"
                        >
                            <option disabled selected>D. Venc.</option>
                            <option v-for="(diaVencimiento, index) in dVenc" :value="diaVencimiento" :key="index">
                                {{ diaVencimiento }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Sexos:</span> 
                        <select
                        id="buscarzona"
                        v-model="buscarSexos"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        @change="findSexos"
                        >
                            <option disabled selected>Sexos</option>
                            <option v-for="(sexo, index) in sexos" :value="sexo" :key="index">
                                {{ sexo }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Nueva:</span> 
                        <select
                        id="buscarzona"
                        v-model="buscarNuevas"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        @change="findNuevas"
                        >
                            <option disabled selected>Nueva</option>
                            <option v-for="(nueva, index) in esNueva" :value="nueva" :key="index">
                                {{ nueva }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Dcto:</span> 
                        <select
                        id="buscarzona"
                        v-model="buscarTiposDcto"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        @change="findTiposDcto"
                        >
                            <option disabled selected>Dcto</option>
                            <option v-for="(tipoDcto, index) in tiposDcto" :value="tipoDcto" :key="index">
                                {{ tipoDcto }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">CDR:</span> 
                        <select
                        id="buscarzona"
                        v-model="buscarCDR"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        @change="findCDR"
                        >
                            <option disabled selected>CDR</option>
                            <option v-for="(tipoCDR, index) in tiposCDR" :value="tipoCDR" :key="index">
                                {{ tipoCDR.replace(/([A-Z])/g, ' $1').trim().replace('C D R', 'CDR') }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="search-box mx-auto inline-block my-auto w-full"
                    >
                        <span class=" absolute label-filtro">Fact x Pag.:</span> 
                        <select
                        id="buscarzona"
                        v-model="tamanoPagina"
                        class="border rounded-xl shadow h-9 bg-white w-full"
                        >
                        <option disabled selected>Fact. X Pag.</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                        </select>
                    </div>
                    <div class="relative inline-block w-44 h-9 mx-auto grid grid-cols-7 my-auto text-blue-900 col-span-2">
                        <div class=" table mx-auto cursor-pointer" v-show=" paginaActual >= 2" @click="inicioPag()">
                            <i class=" relative flex w-full h-5 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path fill="currentColor" d="M77.25 256l137.4-137.4c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0l-160 160c-12.5 12.5-12.5 32.75 0 45.25l160 160C175.6 444.9 183.8 448 192 448s16.38-3.125 22.62-9.375c12.5-12.5 12.5-32.75 0-45.25L77.25 256zM269.3 256l137.4-137.4c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0l-160 160c-12.5 12.5-12.5 32.75 0 45.25l160 160C367.6 444.9 375.8 448 384 448s16.38-3.125 22.62-9.375c12.5-12.5 12.5-32.75 0-45.25L269.3 256z"/>
                                </svg>
                            </i>
                        </div>
                        <div class=" table mx-auto cursor-pointer" @click="retrocederPag()" v-show=" paginaActual >= 2">
                            <i class=" relative flex w-full h-5 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/>
                                </svg>
                            </i>
                        </div>
                        <div class=" table mx-auto col-span-2">
                            <div class=" relative flex w-full h-9 text-xl mt-1">
                                {{ paginaActual }}
                            </div>
                        </div>
                        <div class=" table mx-auto cursor-pointer" @click="avanzarPag()" v-show=" paginaActual < cantPaginas">
                            <i class=" relative flex w-full h-5 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/>
                                </svg>
                            </i>
                        </div>
                        <div class=" table mx-auto cursor-pointer " v-show=" paginaActual < cantPaginas" @click="finPag()">
                            <i class=" relative flex w-full h-5 mt-2 bg-">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path fill="currentColor" d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"/>
                                </svg>
                            </i>
                        </div>
                        <div class=" btn_pag relative mx-auto cursor-pointer rounded-full p-2 h-9 w-9 ml-3 " @click="limpiarfiltrosPendientesAux()">
                            <i class=" relative w-5 h-5 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path fill="currentColor" d="M480 416C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H150.6C133.7 480 117.4 473.3 105.4 461.3L25.37 381.3C.3786 356.3 .3786 315.7 25.37 290.7L258.7 57.37C283.7 32.38 324.3 32.38 349.3 57.37L486.6 194.7C511.6 219.7 511.6 260.3 486.6 285.3L355.9 416H480zM265.4 416L332.7 348.7L195.3 211.3L70.63 336L150.6 416L265.4 416z"/>
                                </svg>
                            </i>
                        </div>
                    </div>
                </div>
                <div id="cont_AsesorasCobranza" 
                class=" rounded-lg overflow-hidden border border-gray-300 overflow-x-scroll">
                    <table id="table_AsesorasCobranza" 
                        class=" relative table-auto w-full">
                        <thead class=" relative block bg-blue-900 text-white border-collapse">
                            <tr class=" grid grid-flow-col auto-cols-max w-full relative mx-auto">
                                <th class=" relative inline-block text-center py-2 celda-mn ">G.Cob</th>
                                <th class=" relative inline-block text-center py-2 celda-mn ">Corte</th>
                                <th class=" relative inline-block text-center py-2 celda-mn ">Región</th>
                                <th class=" relative inline-block text-center py-2 celda-mn ">Zona</th>
                                <th class=" relative inline-block text-center py-2 celda-mn ">Dcto</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Cédula</th>
                                <th class=" relative inline-block text-center py-2 celda-nombre ">Nombre</th>
                                <th class=" relative inline-block text-center py-2 celda-mn ">Sexo</th>
                                <th class=" relative inline-block text-center py-2 celda-mn ">Nueva</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Clasificación</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Fecha Fact</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Fecha Venc</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Dias Venc</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Campaña</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Nro.Fact</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Val.Fac</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Saldo.Fact</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Saldo Total</th>
                                <th class=" relative inline-block text-center py-2 celda-mx ">Fact. a Pagar</th>
                            </tr>
                        </thead>
                        <tbody class=" grid relative block overflow-y-scroll overflow-x-hidden max-h-96">
                            <tr class=" grid grid-flow-col auto-cols-max w-full relative border border-t-gray-300 " v-for=" (item,index) in paginado" :key="index" :class="item.gestion">
                                <td class=" relative inline-block text-center py-2 celda-mn "><router-link :to="'/Cobranza/Gestiones/' + item.numFactura" id="btnGestion" class="relative block rounded-full text-white w-6 h-6 mx-auto cursor-pointer"><i class="fas fa-headset"></i></router-link></td>
                                <td class=" relative inline-block text-center py-2 celda-mn ">{{ item.corte }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mn ">{{ item.region }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mn ">{{ item.zona }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mn ">{{ item.tipoDcto }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.cedula }}</td>
                                <td class=" relative inline-block text-left py-2 celda-nombre ">{{ item.nombreDeudor }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mn ">{{ item.sexo }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mn ">{{ item.esNueva }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.clasificacion }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.fechaFactura }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.fechaVencimiento }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.dVenc }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.campaniaFactura }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.numFactura }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.valorFactura  }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.saldoFactura }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.saldoTotal }}</td>
                                <td class=" relative inline-block text-center py-2 celda-mx ">{{ item.facturasPorPagar }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "@/logic/auth";
import axios from "axios";
import { mapState, mapMutations} from 'vuex'

export default{
    data(){
        return {
            idUsuarioAux: "",
            
            cortes: [],
            regiones: [],
            zonas: [],
            campanias: [],
            dVenc: [],
            sexos: [],
            esNueva: [],
            tiposDcto: [],
            tiposCDR: [],

            buscarCedula: "",
            buscarCorte: "",
            buscarRegion: "",
            buscarZona: "",
            buscarCampania: "",
            buscarDVenc: "",
            buscarSexos: "",
            buscarNuevas: "",
            buscarTiposDcto: "",
            buscarCDR: "",
            auxdata: [],

            filtrosPendientesAux: {},

            tamanoPagina: 10,
            paginaActual: 1,
        }
    },
    methods: {
        ...mapMutations([
            "loadNavbar",
            "hiddenNavbar",
            "logueo",
            "openNavCobranza",
            "openModalCargando",
            "closeModalCargando",
            "setClientesFactPendientes",
            "setfiltradoPendientes",
            "setfiltrosPendientes",
            "limpiarfiltrosPendientes",
            "setInfoAgentesWolkvoxCampActiva",
            "setInfoAgentesWolkvoxIdCampActiva",
            "setUbicacionCobranza"
        ]),
        filtrar(cliente){
            return Object.keys(this.filtrosPendientes).every((key) => cliente[key] === this.filtrosPendientes[key]);
        },
        findtext() {
            let aux = this.filtradoPendientes.filter((x) =>
                x["cedula"].toString().includes(this.buscarCedula)
            );
            if (aux.length == 0) {
                this.$alert(
                    "No hay Facturas que Coincidan",
                    "Verifique la Cedula!",
                    "warning"
                );
            }
            if (this.buscarCedula !== undefined) {
                if (this.buscarCedula !== "") {
                    this.setfiltradoPendientes(aux)
                    this.asignarfiltrosPendientes()
                }
            }
        },
        findCorte() {
            if(this.buscarCorte != undefined){
                if(this.buscarCorte != ""){
                    this.filtrosPendientesAux.corte = this.buscarCorte
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }
            }
        },
        findRegiones() {
            if(this.buscarRegion != undefined){
                if(this.buscarRegion != ""){
                    this.filtrosPendientesAux.region = this.buscarRegion
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }
            }
        },
        findZona() {
            if(this.buscarZona != undefined){
                if(this.buscarZona != ""){
                    this.filtrosPendientesAux.zona = this.buscarZona
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }
            }
        },
        findCampania() {
            if(this.buscarCampania != undefined){
                if(this.buscarCampania != ""){
                    this.filtrosPendientesAux.campaniaFactura = this.buscarCampania
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }
            }
        },
        findDVenc() {
            if(this.buscarDVenc != undefined){
                if(this.buscarDVenc != ""){
                    this.filtrosPendientesAux.dVenc = this.buscarDVenc
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }

            }
        },
        findSexos() {
            if(this.buscarSexos != undefined){
                if(this.buscarSexos != ""){
                    this.filtrosPendientesAux.sexo = this.buscarSexos
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }
            }
        },
        findNuevas() {
            if(this.buscarNuevas != undefined){
                if(this.buscarNuevas != ""){
                    this.filtrosPendientesAux.esNueva = this.buscarNuevas
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }
            }
        },
        findTiposDcto() {
            if(this.buscarTiposDcto != undefined){
                if(this.buscarTiposDcto != ""){
                    this.filtrosPendientesAux.tipoDcto = this.buscarTiposDcto
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }
            }
        },
        findCDR() {
            if(this.buscarCDR != undefined){
                if(this.buscarCDR != ""){
                    this.filtrosPendientesAux.gestion = this.buscarCDR
                    this.setfiltrosPendientes(this.filtrosPendientesAux)
                    this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
                    this.asignarfiltrosPendientes()
                }
            }
        },
        asignarfiltrosPendientes(){
            var data2 = "";
            this.cortes = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.corte;
                if (data != data2) {
                    for (var i = 0; i < this.cortes.length; i++){
                        if (this.cortes[i] === data) {
                            this.cortes.splice(i, 1)
                        }
                    }
                    this.cortes.push(data)
                };
                data2 = element.corte;
            });
            this.cortes.sort();
            data2 = "";

            this.regiones = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.region;
                if (data != data2) {
                    for (var i = 0; i < this.regiones.length; i++){
                        if (this.regiones[i] === data) {
                            this.regiones.splice(i, 1)
                        }
                    }
                    this.regiones.push(data)
                };
                data2 = element.region;
            });
            this.regiones.sort();
            data2 = "";

            this.zonas = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.zona;
                if (data != data2) {
                    for (var i = 0; i < this.zonas.length; i++){
                        if (this.zonas[i] === data) {
                            this.zonas.splice(i, 1)
                        }
                    }
                    this.zonas.push(data)
                };
                data2 = element.zona;
            });
            this.zonas.sort();
            data2 = "";

            this.campanias = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.campaniaFactura;
                if (data != data2) {
                    for (var i = 0; i < this.campanias.length; i++){
                        if (this.campanias[i] === data) {
                            this.campanias.splice(i, 1)
                        }
                    }
                    this.campanias.push(data)
                };
                data2 = element.campaniaFactura;
            });
            this.campanias.sort();
            data2 = "";

            this.dVenc = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.dVenc;
                if (data != data2) {
                    for (var i = 0; i < this.dVenc.length; i++){
                        if (this.dVenc[i] === data) {
                            this.dVenc.splice(i, 1)
                        }
                    }
                    this.dVenc.push(data)
                };
                data2 = element.dVenc;
            });
            this.dVenc.sort();
            data2 = "";

            this.sexos = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.sexo;
                if (data != data2) {
                    for (var i = 0; i < this.sexos.length; i++){
                        if (this.sexos[i] === data) {
                            this.sexos.splice(i, 1)
                        }
                    }
                    this.sexos.push(data)
                };
                data2 = element.sexo;
            });
            this.sexos.sort();
            data2 = "";

            this.esNueva = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.esNueva;
                if (data != data2) {
                    for (var i = 0; i < this.esNueva.length; i++){
                        if (this.esNueva[i] === data) {
                            this.esNueva.splice(i, 1)
                        }
                    }
                    this.esNueva.push(data)
                };
                data2 = element.sexo;
            });
            this.esNueva.sort();
            data2 = "";

            this.tiposDcto = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.tipoDcto;
                if (data != data2) {
                    for (var i = 0; i < this.tiposDcto.length; i++){
                        if (this.tiposDcto[i] === data) {
                            this.tiposDcto.splice(i, 1)
                        }
                    }
                    this.tiposDcto.push(data)
                };
                data2 = element.tipoDcto;
            });
            this.tiposDcto.sort();
            data2 = "";

            this.tiposCDR = []
            this.filtradoPendientes.forEach((element) => {
                var data = element.gestion;
                if (data != data2) {
                    for (var i = 0; i < this.tiposCDR.length; i++){
                        if (this.tiposCDR[i] === data) {
                            this.tiposCDR.splice(i, 1)
                        }
                    }
                    this.tiposCDR.push(data)
                };
                data2 = element.tiposCDR;
            });
            this.tiposCDR.sort();
            data2 = "";
        },
        limpiarfiltrosPendientesAux(){
            this.filtrosPendientesAux = {}
            this.limpiarfiltrosPendientes()
            this.buscarCedula = ""
            this.buscarCorte = ""
            this.buscarRegion = ""
            this.buscarZona = ""
            this.buscarCampania = ""
            this.buscarDVenc = ""
            this.buscarSexos = ""
            this.buscarNuevas = ""
            this.buscarTiposDcto = ""
            this.buscarCDR = ""
            this.setfiltradoPendientes(this.ClientesFactPendientes.filter(this.filtrar,this.filtrosPendientes))
            this.asignarfiltrosPendientes()
        },
        getFacturasClientes(){
            try {
                var user = auth.getUserLogged();
                user = JSON.parse(user);
                if (user.token == "" || user.token == undefined) {
                    this.$router.push("/");
                } else {
                    this.display = "block";
                    this.usuario = user.usuario;
                    this.idUsuarioAux = user.id;
                    this.loadNavbar(user);
                }
                this.openModalCargando()
                axios
                    .get(this.$hostname + "/Api/Clientes/GetClientesFactCobSP/" + user.loginUsuario, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + user.token,
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                    .then((result) => {
                        this.setClientesFactPendientes(result.data)
                        this.limpiarfiltrosPendientesAux()
                        this.setfiltradoPendientes(this.ClientesFactPendientes)
                        this.asignarfiltrosPendientes()
                        this.closeModalCargando()
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$alert(
                            "Por favor vuelve a ingresar a la plataforma",
                            "Expiro la Sesion!",
                            "error"
                        );
                        this.$router.push("/");
                    });
            } catch (err) {
                this.$alert(
                    "Por favor vuelve a ingresar a la plataforma",
                    "Expiro la Sesion!",
                    "error"
                );
                this.$router.push("/");
            }
        },
        crearCampWolkvox(){
            var user = auth.getUserLogged();
                user = JSON.parse(user);
            let nombreCampWV = user.loginUsuario
            var montarCamp = false

            var idCamp

            //por error con el api de wolkvox al hacer el request desde localhost, se usa un proxy con el paquete npm install -g local-cors-proxy
            var host = this.$hostname == "https://localhost:44320" ? "http://localhost:8010/proxy/" : "https://wv0044.wolkvox.com/"
            //para activar el proxy en consola colocar lcp --proxyUrl https://wv0044.wolkvox.com/, este se reemplaza en la url del request por http://localhost:8010/proxy/

            if(this.buscarCedula !== ""){
                if(this.buscarCedula !== undefined){
                    nombreCampWV = nombreCampWV + '_CEDULA:' + this.buscarCedula 
                    montarCamp = true
                }
            }
            if(this.buscarCampania !== ""){
                if(this.buscarCampania !== undefined){
                    nombreCampWV = nombreCampWV + '_C' + this.buscarCampania.substring(4,6) + '-' + this.buscarCampania.substring(0,4) 
                    montarCamp = true
                }
            }
            if(this.buscarCDR !== ""){
                if(this.buscarCDR !== undefined){
                    nombreCampWV = nombreCampWV + '_CDR:' + this.buscarCDR 
                    montarCamp = true
                }
            }
            if(this.buscarDVenc !== ""){
                if(this.buscarDVenc !== undefined){
                    nombreCampWV = nombreCampWV + '_MOTIVO:' + this.buscarDVenc
                    montarCamp = true
                }
            }
            if(this.buscarCorte !== ""){
                if(this.buscarCorte !== undefined){
                    nombreCampWV = nombreCampWV + '_CORTE:' + this.buscarCorte 
                    montarCamp = true
                }
            }
            if(this.buscarRegion !== ""){
                if(this.buscarRegion !== undefined){
                    nombreCampWV = nombreCampWV + '_REG:' + this.buscarRegion.trim() 
                    montarCamp = true
                }
            }
            if(this.buscarZona !== ""){
                if(this.buscarZona !== undefined){
                    nombreCampWV = nombreCampWV + '_ZONA:' + this.buscarZona 
                    montarCamp = true
                }
            }
            if(this.buscarSexos !== ""){
                if(this.buscarSexos !== undefined){
                    nombreCampWV = nombreCampWV + '_SEXO:' + this.buscarSexos 
                    montarCamp = true
                }
            }
            if(this.buscarNuevas !== ""){
                if(this.buscarNuevas !== undefined){
                    nombreCampWV = nombreCampWV + '_NUEVAS:' + this.buscarNuevas 
                    montarCamp = true
                }
            }
            if(this.buscarTiposDcto !== ""){
                if(this.buscarTiposDcto !== undefined){
                    nombreCampWV = nombreCampWV + '_TIPODCTO:' + this.buscarTiposDcto 
                    montarCamp = true
                }
            }
            this.$prompt(
                "Ingresa un nombre para la nueva Campaña Wolkvox, con corte a los 50 caracteres, vas a ingresar " + this.filtradoPendientes.length + " facturas",
                nombreCampWV,
                "Crear Campaña Wolkvox",
                "question"
            )
                .then(text => {
                    this.openModalCargando()
                    var registrosCamp = []
                    var objRegstroCamp = {}
                    this.filtradoPendientes.forEach((element) => {
                        objRegstroCamp = {
                            customer_name: element.nombreDeudor,
                            customer_last_name: "",
                            id_type: element.tipoDcto,
                            customer_id: element.numFactura,
                            age: "",
                            gender: element.sexo,
                            country: "",
                            state: "",
                            city: "",
                            zone: "",
                            address: "",
                            opt1: element.cedula,
                            opt2: element.corte,
                            opt3: element.zona,
                            opt4: element.campaniaFactura,
                            opt5: element.gestion,
                            opt6: element.saldoTotal,
                            opt7: element.nombreR1,
                            opt8: element.telefonoR1,
                            opt9: element.nombreR2,
                            opt10:element.telefonoR2,
                            opt11: element.nombreCodeudor,
                            opt12: element.telefonoCodeudor,
                            tel1: "9" + element.telefono1Deudor,
                            tel2: "9" + element.telefono2Deudor,
                            tel3: "9" + element.telefono3Deudor,
                            tel4: "",
                            tel5: "",
                            tel6: "",
                            tel7: "",
                            tel8: "",
                            tel9: "",
                            tel10: "",
                            agent_id: this.infoAgentesWolkvox.idAgente
                        }
                        registrosCamp.push(objRegstroCamp)
                    });
                    axios
                        .post(this.$hostname + "/api/WolkvoxAPI/crearCampana/",
                        {
                            token: this.infoAgentesWolkvox.token,
                            idUsuario: "" + user.id,
                            body: {
                                encabezado: {
                                    campaign_name: text.substring(0,49),
                                    campaign_description: "Creada por: " + user.loginUsuario,
                                    start_time: "000000",
                                    end_time: "235959",
                                    skill_id: "4190",
                                    opt1: "Cedula",
                                    opt2: "Corte",
                                    opt3: "Zona",
                                    opt4: "Camp.Factura",
                                    opt5: "Tiene CDR",
                                    opt6: "Saldo Total",
                                    opt7: "Nombre Referencia 1",
                                    opt8: "Telefonos Referencia 1",
                                    opt9: "Nombre Referencia 2",
                                    opt10: "Telefonos Referencia 2",
                                    opt11: "Nombre Codeudor",
                                    opt12: "Telefonos Codeudor",
                                    enable_edition: "yes"
                                },
                                detalle: registrosCamp
                            },
                        }, 
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + user.token,
                            },
                        })
                        .then((response) => {
                            //console.log(response)
                            this.setInfoAgentesWolkvoxCampActiva(true)
                            this.setInfoAgentesWolkvoxIdCampActiva(response.data.idCamp)
                            this.closeModalCargando()
                            this.$alert(
                                "Nombre: " + text.substring(0,49),
                                "Se creo y " + response.data.resultado + " #" + response.data.idCamp,
                                "success"
                            )
                            this.closeModalCargando()
                        })
                        .catch((err) => {
                            console.log(err);
                            this.$alert(
                                err,
                                "Error!",
                                "error"
                            );
                        });
                });
        },
        cerrarCampWolkvox(){
            this.openModalCargando()
            var user = auth.getUserLogged();
                user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/Usuarios/ActualizarCampInfoAgentesWolkvox/" + user.id + "/0", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((response) => {
                    this.setInfoAgentesWolkvoxCampActiva(false)
                    this.setInfoAgentesWolkvoxIdCampActiva(0)
                    this.closeModalCargando()
                    this.$alert(
                        "Ahora ya puedes crear una nueva Campaña",
                        response.data[0].resultado,
                        "success"
                    )
                })
                .catch((err) => {
                    console.log(err);
                    this.closeModalCargando()
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
        },
        avanzarPag(){
        if(this.paginaActual < this.cantPaginas){
            this.paginaActual = this.paginaActual + 1
        }else{
            this.$alert(
                "No Existen Mas Paginas",
                "Error!",
                "error"
            )
        }
        },
        retrocederPag(){
            if(this.paginaActual > 0 ){
                this.paginaActual = this.paginaActual - 1
            }else{
                this.$alert(
                    "Estas En La Primera Pagina",
                    "Error!",
                    "error"
                )
            }
        },
        inicioPag(){
            this.paginaActual = 1
        },
        finPag(){
            this.paginaActual = this.cantPaginas
        }
    },
    created() {
        this.openModalCargando()
        this.openNavCobranza()
        try {
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            if (user.token == "" || user.token == undefined) {
                this.$router.push("/");
            } else {
                this.display = "block";
                this.usuario = user.usuario;
                this.idUsuarioAux = user.id;
                this.loadNavbar(user);
                if(this.ClientesFactPendientes.length == 0){
                    axios
                        .get(this.$hostname + "/Api/Clientes/GetClientesFactCobSP/" + user.loginUsuario, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + user.token,
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                        .then((result) => {
                            this.setClientesFactPendientes(result.data)
                            this.setfiltradoPendientes(this.ClientesFactPendientes)
                            this.asignarfiltrosPendientes()
                            this.closeModalCargando()
                        })
                        .catch((err) => {
                            console.log(err);
                            this.closeModalCargando()
                            this.$alert(
                                "Por favor vuelve a ingresar a la plataforma",
                                "Expiro la Sesion!",
                                "error"
                            );
                            this.$router.push("/");
                        });
                }else{
                    this.setfiltradoPendientes(this.ClientesFactPendientes) 
                    this.asignarfiltrosPendientes()
                    //console.log(this.filtrosPendientes)
                    //console.log(this.filtradoPendientes)
                    this.filtrosPendientesAux = this.filtrosPendientes
                    //console.log(this.filtrosPendientesAux.corte)
                    //console.log(this.filtrosPendientesAux.region)
                    //console.log(this.filtrosPendientesAux.zona)
                    //console.log(this.filtrosPendientesAux.campaniaFactura)
                    //console.log(this.filtrosPendientesAux.dVenc)
                    //console.log(this.filtrosPendientesAux.sexo)
                    //console.log(this.filtrosPendientesAux.esNueva)
                    //console.log(this.filtrosPendientesAux.tipoDcto)
                    if(this.filtrosPendientesAux.corte != undefined){
                        if(this.filtrosPendientesAux.corte != ""){
                            this.buscarCorte = this.filtrosPendientesAux.corte
                            this.findCorte()
                        }
                    }
                    if(this.filtrosPendientesAux.region != undefined){
                        if(this.filtrosPendientesAux.region != ""){
                            this.buscarRegion = this.filtrosPendientesAux.region
                            this.findRegiones()
                        }
                    }
                    if(this.filtrosPendientesAux.zona != undefined){
                        if(this.filtrosPendientesAux.zona != ""){
                            this.buscarZona = this.filtrosPendientesAux.zona
                            this.findZona()
                        }
                    }
                    if(this.filtrosPendientesAux.campaniaFactura != undefined){
                        if(this.filtrosPendientesAux.campaniaFactura != ""){
                            this.buscarCampania = this.filtrosPendientesAux.campaniaFactura
                            this.findCampania()
                        }
                    }
                    if(this.filtrosPendientesAux.dVenc != undefined){
                        if(this.filtrosPendientesAux.dVenc != ""){
                            this.buscarDVenc = this.filtrosPendientesAux.dVenc
                            this.findDVenc()
                        }
                    }
                    if(this.filtrosPendientesAux.sexo != undefined){
                        if(this.filtrosPendientesAux.sexo != ""){
                            this.buscarSexos = this.filtrosPendientesAux.sexo
                            this.findSexos()
                        }
                    }
                    if(this.filtrosPendientesAux.esNueva != undefined){
                        if(this.filtrosPendientesAux.esNueva != ""){
                            this.buscarNuevas = this.filtrosPendientesAux.esNueva
                            this.findNuevas()
                        }
                    }
                    if(this.filtrosPendientesAux.tipoDcto != undefined){
                        if(this.filtrosPendientesAux.tipoDcto != ""){
                            this.buscarTiposDcto = this.filtrosPendientesAux.tipoDcto
                            this.findTiposDcto()
                        }
                    }
                    if(this.filtrosPendientesAux.gestion != undefined){
                        if(this.filtrosPendientesAux.gestion != ""){
                            this.buscarCDR = this.filtrosPendientesAux.gestion
                            this.findCDR()
                        }
                    }
                    this.closeModalCargando()
                }
            }
        } catch (err) {
            this.$router.push("/");
        }
        this.setUbicacionCobranza("Pendientes")
    },
    computed: {
        ...mapState(["ClientesFactPendientes","filtradoPendientes","filtrosPendientes","infoAgentesWolkvox"]),
        indexInicio() {
            return (this.paginaActual - 1) * this.tamanoPagina;
        },
        indexFin() {
            return this.indexInicio + this.tamanoPagina;
        },
        paginado() {
            return this.filtradoPendientes.slice(this.indexInicio, this.indexFin);
        },
        cantPaginas(){
            return Math.ceil(this.filtradoPendientes.length / this.tamanoPagina)
        },
        infoAgentesWolkvoxIdCampActiva(){
            if(this.infoAgentesWolkvox == undefined){
                return undefined
            }else{
                return this.infoAgentesWolkvox.idCampActiva
            }
        }
    },
}
</script>

<style scoped>
    #table_AsesorasCobranza{
        min-width: 1000px;
    }
    .celda-nombre{
        width: 400px;
    }
    .celda-mn{
        width: 60px;
    }
    .celda-mx{
        width: 105px;
    }
    .search-input {
        padding: 10px;
        border: 2px solid #111d5e;
        border-radius: 10px 0 0 10px;
        border-right: none;
        outline: none;
        font-size: 20px;
        color: rgb(0, 0, 0);
        width: 100%;
    }
    .search-button {
        text-align: center;
        outline: none;
        cursor: pointer;
        border: 2px solid #111d5e;
        border-radius: 0 10px 10px 0;
        border-left: none;
        font-size: 20px;
        border-left: 2px solid #111d5e;
        width: 100%;
    }
    #btnGestion{
        padding: 0.2px;
    }
    #btnGestion:hover, .btn_pag:hover{
        transform: scale(1.05);
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0,0.4);
    }
    .label-filtro{
        transform: translateY(-1.3rem);
    }
    .ConCDR{
        background: rgb(160, 195, 255);
        color: rgb(0, 65, 122);
    }
    .ConCDR #btnGestion{
        background: linear-gradient(120deg, rgb(160, 195, 255) 0%, rgb(0, 65, 122) 100%);
    }
    #btnGestion, .btn_pag{
        display: flex;
        transition: 0.2s ease-in-out;
        box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 60%);
        background: linear-gradient(120deg, rgba(37,99,235,1) 0%, rgba(30,58,138,1) 100%);
        align-items: center;
        justify-content: center;
    }
    .btn_refrescar {
        position: absolute;
        display: block;
        top: 4.6rem;
        right: 1rem;
    }
    .btn_refrescar i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .btn-Wolkvox {
        width: 2.3rem;
        padding: 0.2rem;
        border-radius: 100%;
        background: linear-gradient(120deg, rgba(37,99,235,1) 0%, rgba(30,58,138,1) 100%);
        box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 40%);
        cursor: pointer;
    }
    .cont_Camp-Wolkvox {
        top: -0.5rem;
        position: relative;
    }
    .Camp-Wolkvox {
        display: flex;
        background: #f2950b;
        color: #000;
        border-radius: 1rem;
        padding: 0.6rem;
        font-size: 1.1rem;
        font-weight: 500;
        position: relative;
        cursor: pointer;
        align-items: center;
    }
    .Camp-Wolkvox img {
        width: 2rem;
        filter: invert(1);
        margin-right: 0.5rem;
    }
    .Camp-Wolkvox i {
        margin-left: 0.5rem;
    }
</style>